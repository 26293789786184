import bigcommerceSparkDefault from '../bigcommerce-spark/config.js';

bigcommerceSparkDefault.Widgets.find((w) => w.name === 'FacetBrandTiles').location = '#cm_brands';

const categorySelectionPageUrl = '/category-list/';
const brandSelectionPageUrl = '/shop-by-brands/';

// the customer asked us to turn off the vin search
// window.Convermax.onDialogTabsClick = (e) => {
//   const clickedTabTitle = e.target.closest('.cm_tab-title');
//   if (clickedTabTitle && !clickedTabTitle.classList.contains('active')) {
//     const newActiveTab = [...clickedTabTitle.classList].find((cl) => cl.endsWith('-search'));
//     const currentActiveElems = [...e.currentTarget.querySelectorAll('.active')];
//     const newActiveElems = [...e.currentTarget.querySelectorAll(`.${newActiveTab}`)];
//     [...currentActiveElems, ...newActiveElems].forEach((elem) => elem.classList.toggle('active'));
//   }
// };

export default {
  includes: ['bigcommerce-spark'],
  ...bigcommerceSparkDefault,
  fitmentSearch: {
    ...bigcommerceSparkDefault.fitmentSearch,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
  },
  Widgets: [
    ...bigcommerceSparkDefault.Widgets.filter((w) => !['HomeVehicleWidget'].includes(w.name)),
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 700,
    },
    // the customer asked us to turn off the vin search
    // {
    //   name: 'SearchBox_dialogtab',
    //   type: 'SearchBox',
    //   disableDropdown: true,
    // },
    // {
    //   name: 'SearchBox_hometab',
    //   type: 'SearchBox',
    //   location: { selector: '.cm_tab-content.cm_vin-search', class: 'cm_search-box-root__hometab' },
    //   template: 'SearchBox',
    //   disableDropdown: true,
    // },
  ],
};
