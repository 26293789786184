//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-404:_2252,_4032,_9980,_6075,_5008,_3724,_1643,_6684;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-404')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-404', "_2252,_4032,_9980,_6075,_5008,_3724,_1643,_6684");
        }
      }catch (ex) {
        console.error(ex);
      }